import { logError } from "@telia/b2x-logging";

export type AgreementsFromBackendType = {
  id?: string;
  number?: string;
  name?: string;
}[];

export type AgreementsForFrontendType = {
  label: string;
  value: string;
}[];

const buildLabel = (name?: string, number?: string): string => {
  if (name && number) {
    // normal common case
    return `${name} / ${number}`;
  } else if (number) {
    // happens sometimes, at least on test env
    return number;
  } else if (name) {
    // should never happen
    return name;
  } else {
    // should never happen
    logError("b2b-manage-overview", "Organization has neither name nor number");
    return "-";
  }
};

/**
 *
 * @param unmappedAgreements
 * @param t
 * @returns
 *  1 or more items.
 *  The first is always the special ALL item.
 *  The rest will be agreements, in the order given by backend.
 *  NOTE! Not all subscriptions are part of agreements.
 *  User selecting "ALL" agreements means, show all subscriptions including those that does not belong to an agreement.
 *  Hence, if only a single agreement exists, it cannot be hidden or treated the same as "ALL" (unlike tscids).
 */
export const mapAgreements = (
  unmappedAgreements: AgreementsFromBackendType,
  t: (key: string) => string
): AgreementsForFrontendType => [
  {
    label: t("MANAGE_OVERVIEW.SHOW_ALL_AGREEMENTS"),
    value: "ALL",
  },
  ...unmappedAgreements.map((agreement) => ({
    label: buildLabel(agreement.name, agreement.number),
    value: agreement.id ?? "",
  })),
];
