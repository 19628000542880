import { logError } from "@telia/b2x-logging";

import { corpInstalledbaseListPagination } from "@telia/b2b-rest-client";

import { postToUrl } from "./post-to-url-service";

import { mapExportType, mapRequestData } from "./subscription-mapper";

export const getSubscriptions = (
  scopeId: string,
  selectedOrganization: string,
  selectedAgreement: string,
  selectedMainCategory: string,
  selectedSubCategories: Array<string>,
  columnFilter: Record<string, string>,
  sort: { column: string; direction: "ASC" | "DESC" },
  selectedPageNumber: number, // first page = 1!
  selectedPageSize: number
) => {
  if (selectedSubCategories.some((selectedSubCategory) => selectedSubCategory === null)) {
    // This happened once when selecting/deselecting subcategories randomly.
    // May have fixed it by accident, do sanity check and log it to detect if the issue is still there.
    logError(
      "b2b-manage-overview",
      "One of the sub categories requested by FE is null! Backend will fail, user will get error"
    );
  }

  return corpInstalledbaseListPagination.ListPaginationControllerImplService.getPaginatedSubscriptions(
    scopeId,
    {
      ...mapRequestData(
        selectedOrganization,
        selectedAgreement,
        selectedMainCategory,
        selectedSubCategories,
        columnFilter,
        sort
      ),
      limit: selectedPageSize,
      offset: (selectedPageNumber - 1) * selectedPageSize, // first pageNumber is 1, not 0
    }
  );
};

export const postExport = (
  scopeId: string,
  exportType: string,
  selectedOrganization: string,
  selectedAgreement: string,
  selectedMainCategory: string,
  selectedSubCategories: Array<string>,
  columnFilter: Record<string, string>,
  sort: { column: string; direction: "ASC" | "DESC" },
  columns: {
    attribute: string;
    label: string;
    valueLabels?: { value: SVGStringList; label: string }[];
  }[]
): void => {
  postToUrl(
    `/.api/corp-installedbase-list-pagination/scope/${scopeId}/${mapExportType(exportType)}`,
    "data",
    {
      ...mapRequestData(
        selectedOrganization,
        selectedAgreement,
        selectedMainCategory,
        selectedSubCategories,
        columnFilter,
        sort // note: sorting is ignored by BE - should be removed by MYBT-24711
      ),
      columns,
    }
  );
};
