import { logError } from "@telia/b2x-logging";

type OrganizationsFromBackendType = {
  name?: string;
  tscid?: string;
  organizationNumber?: string;
}[];

export type OrganizationsForFrontend = {
  label: string;
  value: string;
}[];

/**
 * @param unmappedOrganizations
 * @param t
 * @returns
 *    Will return at least one item, but never exactly two items.
 *    The first item will ALWAYS have the value "ALL"
 *    If one item, the label of that will be the only organization.
 *    If three or more items, the first item will be a special "All organizations" item,
 *        and the remaining items will be alphabetically sorted by label.
 */
export const mapOrganizations = (
  unmappedOrganizations: OrganizationsFromBackendType,
  t: (key: string) => string
): OrganizationsForFrontend => {
  const sortedOrganizations = unmappedOrganizations.sort((a, b) => {
    // Swedish language rules should always be used regardless of user language, according to the editors
    return (a.name ?? "").localeCompare(b.name ?? "", "sv-SE", {
      sensitivity: "base",
    });
  });

  const mappedOrganizations = sortedOrganizations.map((organization) => ({
    label: organization.name ?? "",
    value: organization.tscid ?? "",
  }));

  if (mappedOrganizations.length <= 0) {
    logError("b2b-manage-overview", "Received zero organizations for a given scope");
    throw new Error("No organizations");
  } else if (mappedOrganizations.length === 1) {
    // specifying tscid filters wastes performance, so if there is a single org,
    // treat it as "ALL", which is much faster but yields same result
    mappedOrganizations[0].value = "ALL";
  } else if (mappedOrganizations.length > 1) {
    // if there are more than one tscids, add "ALL" item to beginning
    mappedOrganizations.unshift({ label: t("MANAGE_OVERVIEW.ALL_ORGANIZATIONS"), value: "ALL" });
  }

  return mappedOrganizations;
};
