<template>
  <!--
    This file should be as passive as possible!
    - It does not have its own state (minimal 'data'), it only renders whatever is provided by props
    - It does not change it's own state or fetch any data.
          - It does some debouncing of filter events which needs some trickery.
    - All events should be representing USER ACTIONS (no events are triggered e.g. by changing props)
          - Not 100% possible, at least columnManagerSelection is triggered when 'columns' change.
    - To change its state, parent component updates props based on events 
    The reason is to try to separate the asynchronous madness in OverviewTable from the actual rendering logic.
    (Once that has been sorted out, maybe we can make components more "self-sufficient")

    .stop is needed on some events to prevent propagation which only seems to happen during unit tests.
  -->
  <div>
    <b2x-controlled-table
      v-show="tableState === 'SUCCESS' || tableState === 'LOADING_CONTENT'"
      :column-filter-label="t('mybusiness.columnManager')"
      :reset-column-label="t('mybusiness.resetColumns')"
      :columns="JSON.stringify(columns)"
      :data="JSON.stringify(rows)"
      :is-wide-table="isWideTable"
      :message="message"
      :clear-filters-button-disabled="clearFiltersButtonDisabled"
      :table-rows-count="rowCount"
      :table-rows-limit="data.length"
      :export-btn-label="t('MANAGE_OVERVIEW.TABLE.EXPORT_BUTTON_LABEL')"
      :export-line-label="t('MANAGE_OVERVIEW.TABLE.EXPORT_LINE_LABEL')"
      :export-types="JSON.stringify(exportTypes)"
      ref="b2xControlledTable"
      show-filter-row
      show-expand-button
      show-clear-filters-button
      @filterColumnInput.stop="onFilterColumnInput"
      @columnClick="onColumnClick"
      @columnManagerSelection="onColumnManagerSelection"
      @clearAllFilters="onClearAllFilters"
      @resetButtonClick="onResetColumns"
      @checkBoxSelect="onCheckboxSelect"
      @exportData="onExportData"
    />
    
    <b2x-table-skeleton
      v-if="tableState === 'LOADING_CONTENT'"
      :rows="selectedPageSize"
      :columns="visibleColumnsCount"
      show-header="false"
    />
  </div>

</template>

<script>
import debounce from "p-debounce";
import { translateMixin } from "../locale";
import TABLE_STATE from "./TableState.enum"; // state for this component and some child components
import { ExportType } from "../b2x-table.enum";

// this import is needed for unit tests, but not for runtime
import { b2xControlledTable } from "@telia/b2x-table";

export default {
  name: "overview-table",

  mixins: [translateMixin],

  props: {
    // should we display the table (with or without rows?)
    visible: { type: Boolean },

    // ERROR - show nothing (parent shows error message)
    // LOADING_EVERYTHING - show nothing (parent shows bigger skeleton)
    // LOADING_CONTENT - show table, but no data (parent shows skeleton below)
    // SUCCESS - show table with content
    tableState: { type: String },

    // should we display the data rows?
    dataIsLoaded: { type: Boolean },

    columns: { type: Array },

    // incremented by parent every time any pending debounced event are no longer relevant.
    columnFilterContext: { type: Number },

    data: { type: Array },
    isWideTable: { type: Boolean },
    clearFiltersButtonDisabled: { type: Boolean },
    rowCount: { type: Number },
    selectedMainCategory: { type: String },
    selectedPageSize: { type: Number }
  },

  data () {
    return {
      pendingColumnEvents: []
    };
  },

  mounted() {
    addEventListener("controlledTableExpandClicked", this.toggleWideTable);
  },

  created() {
    this.debouncedEmitPendingFilterColumnInput = debounce(this.debouncedEmitPendingFilterColumnInput, 500);
  },

  destroyed() {
    removeEventListener("controlledTableExpandClicked", this.toggleWideTable);
  },

  methods: {
    setFilters(filters) {
      this.$refs.b2xControlledTable.setFilters(filters);
    },
    clearAllFilters() {
      this.$refs.b2xControlledTable.clearFilterRow();
    },
    addPendingFilterColumnInput(newEventDetail) {
      // remove any previous event that is rendered obsolete by the new event
      this.pendingColumnEvents = this.pendingColumnEvents.filter(
        (pendingEvent) => pendingEvent.column !== newEventDetail.column
      );

      // WARNING: this is not compatible with "suggestion" filters, which comes as "inputValue"
      this.pendingColumnEvents.push({
        column: newEventDetail.column,
        value: newEventDetail.value,
        automatic: newEventDetail.automatic,
        context: this.columnFilterContext
      });
    },
    debouncedEmitPendingFilterColumnInput() {
      // Debounced!
      while (this.pendingColumnEvents.length > 0) {
        const oldestEvent = this.pendingColumnEvents.shift();
        if (oldestEvent.context === this.columnFilterContext) {
          // event is still relevant, emit it. otherwise, discard it.
          this.$emit('filter-column-input', {
            column: oldestEvent.column,
            value: oldestEvent.value,
            automatic: oldestEvent.automatic,
          });
        }
      }
    },

    toggleWideTable() {
      this.$emit('toggle-wide-table');
    },
    onColumnClick(event) {
      this.$emit('column-click', event.detail);
    },
    onFilterColumnInput(event) {
      this.addPendingFilterColumnInput(event.detail);
      this.debouncedEmitPendingFilterColumnInput();
    },
    onColumnManagerSelection({ detail: columns }) {
      // WARNING: triggered when the "columns" prop changes.
      // which it does when parent changes columns based on this event.
      // The loop stops after setting columns prop the second time.
      // (b2x-table does pass an event when setting it to same value as it already was).
      this.$emit('column-manager-selection', columns);
    },
    onClearAllFilters() {
      this.$emit('clear-all-filters');
    },
    onResetColumns() {
      this.$emit('reset-columns');
    },
    onCheckboxSelect({ detail }) {
      this.$emit('checkbox-select', detail);
    },
    onExportData({ detail }) {
      this.$emit('export-data', detail);
    },
  },

  computed: {
    exportTypes() {
      return [ExportType.csv, ExportType.xlsx];
    },
    message() {
      if (this.rowCount === 0 && this.tableState === TABLE_STATE.SUCCESS) {
        return this.t("MANAGE_OVERVIEW.TABLE.FILTER_NO_RESULTS");
      }
      return null;
    },
    rows() {
      return this.tableState === TABLE_STATE.SUCCESS ? this.data : [];
    },
    visibleColumnsCount() {
      let count = 0;

      this.columns.forEach((column) => {
        if (column.isChecked) {
          count++;
        }
      });

      return count >= 1 ? count : 6;
    },
  },
};
</script>

