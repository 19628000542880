import { SubscriptionUI } from "@telia/b2b-rest-client/dist/corp-installedbase-list-pagination/models/SubscriptionUI";
import { getPersistedState, setPersistedState } from "../services/state-service";
import deepEqual from "fast-deep-equal";
import type { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import type { TableCellValue } from "@telia/b2x-table/dist/types/components/table-cell/table-cell-component-map";
import type { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import columns, { Column, CategoryType } from "./columns";
import { MainCategory as Category } from "@telia/b2b-product-categories-lib";
import { translate } from "../locale";
import { TableColumnFilterable } from "@telia/b2x-table/dist/types/components/table/table";
import { FlexibleWidthCellData } from "@telia/b2x-table/dist/types/components/table-cell/cell-data";
import { TableCellType } from "../b2x-table.enum";

const STATE_ID = "columns";

type Sort = {
  column?: Omit<"legacyUrl", keyof SubscriptionUI>;
  direction?: "ASC" | "DESC";
};

type ColumnSettings = Record<string, boolean>;

type Settings = {
  [Category.ALL]?: ColumnSettings;
  [Category.BROADBAND_AND_BUSINESS_NETWORK]?: ColumnSettings;
  [Category.SERVICE_AND_OTHER]?: ColumnSettings;
  [Category.SWITCH]?: ColumnSettings;
  [Category.TELEPHONY]?: ColumnSettings;
};

enum TableCellWidthVariant {
  CELL_WIDTH_200 = "cell-width-200",
}

const resolveTruncatableFlexibleWidthText = (
  subscription: SubscriptionUI,
  { attribute }: Column
): FlexibleWidthCellData => {
  return {
    content: (subscription[attribute] as string) || "",
    variant: TableCellWidthVariant.CELL_WIDTH_200,
  };
};

class TableManager {
  private settings: Settings;

  constructor() {
    this.settings = this.getDefaultSettings();
  }

  private translateTableColumnTitle(titleKey: string): string {
    return translate(`MANAGE_OVERVIEW.TABLE.COLUMNS.${titleKey}.TITLE`);
  }

  getColumnsByCategory(category: CategoryType): Column[] {
    return columns.filter(({ categories }) => categories.includes(category));
  }

  getTableColumns(category: CategoryType, sort: Sort): TableManagerColumn[] {
    const userSelectedColumns = this.settings?.[category];

    const getTranslatedFilterable = (filterable: TableColumnFilterable): TableColumnFilterable => {
      switch (filterable.type) {
        case "enum":
          return {
            ...filterable,
            label: translate(`MANAGE_OVERVIEW.TABLE.COLUMNS.${filterable.label}.FILTER`),
            noSelectionDisplayName: translate(
              `MANAGE_OVERVIEW.TABLE.COLUMNS.${filterable.label}.NO_SELECTION`
            ),
          };
        case "text":
        default:
          return {
            ...filterable,
            label: translate(`MANAGE_OVERVIEW.TABLE.COLUMNS.${filterable.label}.FILTER`),
          };
      }
    };

    return this.getColumnsByCategory(category).map(
      (column): TableManagerColumn => ({
        title: this.translateTableColumnTitle(column.title),
        type: column.type,
        isChecked:
          typeof userSelectedColumns?.[column.attribute] === "boolean"
            ? userSelectedColumns[column.attribute]
            : column.isChecked,
        disabled: column.disabled,
        columnGroup: translate(`MANAGE_OVERVIEW.TABLE.COLUMN_GROUPS.${column.columnGroup}`),
        sortable: column.sortable && {
          ...column.sortable,
          sortIconName: (() => {
            if (sort?.column === column.attribute) {
              return sort.direction === "ASC" ? "arrow-down" : "arrow-up";
            }

            return "sorter";
          })(),
        },
        filterable: column.filterable && getTranslatedFilterable(column.filterable),
      })
    );
  }

  getDefaultSettings(): Settings {
    return {};
  }

  getTableRowFromSubscription(
    category: CategoryType,
    subscription: SubscriptionUI,
    scopeId: string
  ): TableEntry {
    return this.getColumnsByCategory(category).map((column) => {
      if (column.resolve) {
        return column.resolve(subscription, column, scopeId);
      }

      if (column.type === TableCellType.TRUNCATABLE_FLEXIBLE_WIDTH_TEXT) {
        return resolveTruncatableFlexibleWidthText(subscription, column);
      }

      return (subscription[column.attribute] || "") as TableCellValue;
    });
  }

  findAttributeByColumnTitle(title: string): string | undefined {
    return columns.find((column) => this.translateTableColumnTitle(column.title) === title)
      ?.attribute;
  }

  findColumnTitleByAttribute(attribute: string): string | undefined {
    let column = columns.find(column => column.attribute === attribute);
    if (column) {
      return this.translateTableColumnTitle(column.title);
    } else {
      return undefined;
    }
  }

  getDefaultSort(category: CategoryType): Sort {
    const columns = this.getColumnsByCategory(category || Category.ALL);

    return {
      column: columns[0].attribute as Sort["column"],
      direction: "ASC",
    };
  }

  async fetchSettings(scopeId: string): Promise<void> {
    this.settings = (await getPersistedState(
      scopeId,
      STATE_ID,
      this.getDefaultSettings()
    )) as Settings;

    this.initialColumnsGA();
  }

  initialColumnsGA(): void {
    const displayedColumns: string[] = [];

    if (this.settings?.ALL) {
      for (const [key, value] of Object.entries(this.settings?.ALL)) {
        if (value) {
          displayedColumns.push(key);
        }
      }
    }

    const trackingAction = analytics.action.START_COLUMN;
    const trackingLabel = `Key:${displayedColumns.join("_")}`;

    analytics.trackEvent(analytics.category.MANAGE, trackingAction, trackingLabel);
  }

  async setCategoryColumns(
    scopeId: string,
    category: CategoryType,
    columns: TableManagerColumn[]
  ): Promise<void> {
    const categoryColumns = columns.reduce((acc, column) => {
      const attribute = this.findAttributeByColumnTitle(column.title);

      if (attribute) {
        acc[attribute] = column.isChecked;
      }

      return acc;
    }, {});

    const newSettings = {
      ...this.settings,
      [category]: categoryColumns,
    };

    const settingsHaveChanged = !deepEqual(newSettings, this.settings);

    if (settingsHaveChanged) {
      this.settings = newSettings;
      await setPersistedState(scopeId, STATE_ID, this.settings);
    }
  }

  async resetCategoryColumns(scopeId: string, category: CategoryType): Promise<void> {
    if (this.settings?.[category]) {
      this.settings[category] = undefined;
    }

    await setPersistedState(scopeId, STATE_ID, this.settings);
  }
}

export default TableManager;
