<template>
  <telia-tab-content
    :tab-id="name"
    :name="mainCategoryDisplayName"
    class="overview-category"
    :active="active"
    :is-broadband-and-business-network-product-available="
      isBroadbandAndBusinessNetworkProductAvailable
    "
  >
    <div class="overview-category__tab-content">
      <div v-if="shouldShowFilters" class="overview-category__filters">
        <telia-fieldset :legend="t('MANAGE_OVERVIEW.SELECT_CATEGORIES')" variant="horizontal">
          <telia-chip-choice
            class="overview-category__all-choice"
            :name="name"
            :value="existingAndSelectedSubCategories[0].name"
            :text="existingAndSelectedSubCategories[0].displayName"
            :checked="isFilterSelected('ALL')"
            @click="setDefaultFilters"
          />

          <telia-chip-filter
            v-for="subCategory in existingAndSelectedSubCategories.slice(1)"
            :key="subCategory.name"
            :name="name"
            :value="subCategory.name"
            :text="subCategory.displayName"
            :checked="isFilterSelected(subCategory.name)"
            @click="onFilterClicked"
          />
        </telia-fieldset>
      </div>

      <OverviewNavigation
        :scope-id="scopeId"
        :sub-categories="existingSubCategories.slice(1)"
        :selected-sub-categories="selectedSubCategories"
        :is-broadband-and-business-network-product-available="
          isBroadbandAndBusinessNetworkProductAvailable
        "
      />
    </div>
  </telia-tab-content>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import OverviewNavigation from "./OverviewNavigation.vue";
import { mergeAndSortExistingWithSelectedSubCategories } from "../services/overview-mapper";

export default {
  name: "overview-category",
  mixins: [translateMixin],

  components: {
    OverviewNavigation,
  },

  props: {
    scopeId: { type: String, required: true },
    mainCategoryDisplayName: { type: String, required: true },
    name: { type: String, required: true },

    // subCategories that HAS ITEMS on the current main category
    existingSubCategories: { type: Array, default: () => [] },
    active: { type: Boolean },

    // the subcategories the user has selected, for the current main category. May contain subcategories not included in existingSubCategories.
    // this just defines the starting value. "selectedFilters" holds the actual, current selection.
    // any changes to this after the component is created, is ignored.
    selectedSubCategories: { type: Array, default: () => [] },

    isBroadbandAndBusinessNetworkProductAvailable: { type: Boolean },
  },

  data: () => ({
    selectedFilters: [],
  }),

  async created() {
    translateSetup();

    if (this.selectedSubCategories.length === 0) {
      this.setDefaultFilters();
    } else {
      this.selectedFilters = this.selectedSubCategories;
    }
  },

  computed: {
    existingAndSelectedSubCategories() {
      // if we only show existing filters, it causes several bugs when filter selection contains additional categories
      return mergeAndSortExistingWithSelectedSubCategories(
        this.existingSubCategories,
        this.selectedFilters
      );
    },
    shouldShowFilters() {
      // existingAndSelectedSubCategories = 'ALL' + actual subcategories
      // on the "all main categories" tab, there is only "ALL (0)") that we should not display.
      return this.existingAndSelectedSubCategories?.length > 1;
    },
  },

  methods: {
    isFilterSelected(filter) {
      return this.selectedFilters.includes(filter);
    },
    setDefaultFilters() {
      this.selectedFilters = ["ALL"];
    },
    onFilterClicked(event) {
      // if "ALL" was previously selected, unselect it.
      const indexOfAllFilter = this.selectedFilters.indexOf("ALL");
      if (indexOfAllFilter > -1) {
        this.selectedFilters.splice(indexOfAllFilter, 1);
      }

      // is the newly clicked item selected?
      const filterValue = event.target.value;
      const filterIndex = this.selectedFilters.indexOf(filterValue);

      if (filterIndex > -1) {
        // new item was previously included. remove it from selection.

        const selectedFilters = [...this.selectedFilters];
        selectedFilters.splice(filterIndex, 1);

        if (selectedFilters.length === 0) {
          // everything was unselected, select 'ALL' item again.
          this.setDefaultFilters();
        } else {
          // at least one item was selected, apply the new selection.
          this.selectedFilters = selectedFilters;
        }
      } else {
        // item was previously unselected. select it.
        this.selectedFilters.push(filterValue);
      }
    },
    onChoiceClicked(event) {
      this.selectedFilters = [event.target.value];
    },
    onSelectedFiltersChange() {
      this.$emit("selected-sub-categories", {
        subCategories: this.selectedFilters,
        mainCategory: this.name,
      });
    },
  },
  watch: {
    selectedFilters: { handler: "onSelectedFiltersChange", deep: true },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/borders/mixins";
@import "@teliads/components/foundations/spacing/variables";

.overview-category {
  & &__all-choice {
    margin-right: $telia-spacing-16;
  }

  &__tab-content {
    margin: 0 calc(#{$telia-spacing-24} * -1);
    padding-bottom: $telia-spacing-32;
  }

  &__filters {
    padding-bottom: $telia-spacing-32;
    @include telia-border("gray-500", "xs", "bottom");
  }
}
</style>
