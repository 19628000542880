<template>
  <div
    class="overview-categories"
    :class="{
      'overview-categories--error': status === 'ERROR',
      'overview-categories--loading': status === 'LOADING',
      'overview-categories--success': status === 'SUCCESS',
    }"
  >
    <telia-grid>
      <div :key="this.forceReloadCounter">
        <telia-tab
          v-if="status === 'SUCCESS'"
          variant="wide"
          class="overview-categories__tabs"
          @vocaTabChange="onTabChange"
        >
          <OverviewCategory
            v-for="tab in tabs"
            :key="tab.name"
            v-bind="getCategoryProps(tab.name)"
            :active="isActiveMainCategory(tab)"
            :selected-sub-categories="selectedSubCategories[tab.name]"
            :is-broadband-and-business-network-product-available="
              isBroadbandAndBusinessNetworkProductAvailable
            "
            @selected-sub-categories="onSelectedSubCategories"
          />
        </telia-tab>

        <div v-else-if="shouldShowSkeleton" class="overview-categories__skeleton-tabs">
          <telia-skeleton class="overview-categories__skeleton-tab"></telia-skeleton>
          <telia-skeleton class="overview-categories__skeleton-tab"></telia-skeleton>
          <telia-skeleton class="overview-categories__skeleton-tab"></telia-skeleton>
          <telia-skeleton class="overview-categories__skeleton-tab"></telia-skeleton>
          <telia-skeleton class="overview-categories__skeleton-tab"></telia-skeleton>
        </div>
      </div>
    </telia-grid>
  </div>
</template>

<script>
import { getOverview } from "../services/overview-service";

import OverviewCategory from "./OverviewCategory";

import STATE from "../state.enum";
import { getMainCategories } from "@telia/b2b-product-categories-lib";
import { translateMixin } from "../locale";

export default {
  name: "overview-categories",

  mixins: [translateMixin],

  components: {
    OverviewCategory,
  },

  props: {
    organizationsState: { type: String },

    scopeId: { type: String },
    selectedAgreement: { type: String },
    selectedOrganization: { type: String },
    selectedMainCategory: { type: String },
    selectedSubCategories: { type: Object, default: () => ({}) },
  },

  data: () => ({
    categoriesState: STATE.LOADING,
    mainCategories: {},

    appliedScopeId: null,
    appliedAgreement: null,
    appliedOrganization: null,

    // those two properties are for workaround of voca tabs bug
    forceReloadCounter: 0,
    activeTabAccordingToVocaTabs: undefined,

    fetchOverviewPromiseChain: Promise.resolve(),
  }),

  methods: {
    async fetchOverview() {
      if (!this.scopeId || !this.selectedOrganization || !this.selectedMainCategory) {
        return;
      }

      let somethingHasChanged = false;

      if (this.appliedScopeId !== this.scopeId) {
        this.appliedScopeId = this.scopeId;
        somethingHasChanged = true;
      }

      if (this.appliedOrganization !== this.selectedOrganization) {
        this.appliedOrganization = this.selectedOrganization;
        somethingHasChanged = true;
      }

      if (this.appliedAgreement !== this.selectedAgreement) {
        this.appliedAgreement = this.selectedAgreement;
        somethingHasChanged = true;
      }

      if (somethingHasChanged) {
        this.categoriesState = STATE.LOADING;

        try {
          this.mainCategories = await getOverview(
            this.scopeId,
            this.selectedOrganization,
            this.selectedAgreement
          );
          this.categoriesState = STATE.SUCCESS;
        } catch (error) {
          this.mainCategories = {};
          this.categoriesState = STATE.ERROR;
        }
      }
    },
    emitSelectedMainCategory(category) {
      // the parent will update this.selectedMainCategory which will rerender things.
      this.$emit("selected-main-category", category);
    },
    emitCurrentlySelectedMainCategoryHasItemsChanged() {
      // emit 'HAS_ITEMS', 'HAS_NO_ITEMS' or 'UNKNOWN'
      if (this.categoriesState === STATE.SUCCESS) {
        return this.$emit(
          "currently-selected-main-category-has-items-changed",
          this.mainCategories?.[this.selectedMainCategory].hasItems ? "HAS_ITEMS" : "HAS_NO_ITEMS"
        );
      } else {
        return this.$emit("currently-selected-main-category-has-items-changed", "UNKNOWN");
      }
    },
    getCategoryProps(category) {
      return {
        scopeId: this.scopeId,
        name: category,
        mainCategoryDisplayName: this.mainCategories?.[category]?.displayName,
        existingSubCategories: this.mainCategories?.[category]?.categories,
      };
    },
    isActiveMainCategory(category) {
      return this.selectedMainCategory === category.name;
    },
    onCategoriesStateChange(categoriesState) {
      this.$emit("categories-state", categoriesState);
      this.emitCurrentlySelectedMainCategoryHasItemsChanged();
    },
    onTabChange(event) {
      // keep track of active tab so onPathnameChange can rerender the tabs if it becomes outdated
      this.activeTabAccordingToVocaTabs = event.detail.value;
      this.emitSelectedMainCategory(event.detail.value);
    },
    onSelectedSubCategories(event) {
      this.$emit("selected-sub-categories", event);
    },
    onSelectedMainCategory() {
      // Voca tabs has a bug: after initial render, it ignores any changes to the "active" attribute of telia-tab-content.
      // So after initial render, active tabs will only update if the new tab was selected by clicking the tabs.
      // This caused MYBT-19525 - if we are on some tab other than the default "ALL", and somehow sspa-navigate to the ALL tab,
      // everything will update as expected except the Voca tabs that will still show the previous tab as selected.

      // The below block 'fixes' that by checking if changes to the URL was initiated from the tabs or not.
      // If they don't match, the URL must have been changed from the outside and thus the tabs has to be rerendered.
      if (!this.activeTabAccordingToVocaTabs) {
        // this happens on initial page load.
        // basically, save the originally selected tab.
        this.activeTabAccordingToVocaTabs = this.selectedMainCategory;
      } else if (this.activeTabAccordingToVocaTabs !== this.selectedMainCategory) {
        // URL changed, and doesn't match the last selected tab (e.g. through sspa link in main navigation)
        // this means we need to reload the voca tabs.
        // (Rerendering is a bit disruptive, there is flickering and buttons lose focus etc, so can't do it everytime main category changes.
        this.activeTabAccordingToVocaTabs = this.selectedMainCategory;
        this.forceReloadCounter++;
      } else {
        // the newly selected path match what is selected in the voca tab. This is the normal case, no hack needed.
      }

      this.emitCurrentlySelectedMainCategoryHasItemsChanged();
    },
  },

  computed: {
    tabs() {
      return getMainCategories(true);
    },
    shouldShowSkeleton() {
      return this.categoriesState === STATE.LOADING && this.organizationsState !== STATE.ERROR;
      // organizationState\categoriesState
      //                    LOADING      SUCCESS    ERROR       other
      // LOADING            TRUE         false      false       false
      // SUCCESS            TRUE         false      false       false
      // ERROR              false        false      false       false
      // other              TRUE         false      false       false
    },
    status() {
      if (this.organizationsState === STATE.ERROR) {
        return STATE.ERROR;
      }
      return this.categoriesState;
      // organizationState\categoriesState
      //                    LOADING         SUCCESS     ERROR     other
      // LOADING            LOADING         SUCCESS     ERROR     other
      // SUCCESS            LOADING         SUCCESS     ERROR     other
      // ERROR              ERROR           ERROR       ERROR     ERROR
      // other              LOADING         SUCCESS     ERROR     other
    },
    isBroadbandAndBusinessNetworkProductAvailable() {
      return this.mainCategories?.["BROADBAND_AND_BUSINESS_NETWORK"]?.hasItems ?? false;
    },
  },

  watch: {
    categoriesState: {
      handler: "onCategoriesStateChange",
    },
    scopeId: {
      handler() {
        this.fetchOverviewPromiseChain.then(this.fetchOverview);
      },
      immediate: true,
    },
    selectedAgreement: {
      handler() {
        this.fetchOverviewPromiseChain.then(this.fetchOverview);
      },
      immediate: true,
    },
    selectedOrganization: {
      handler() {
        this.fetchOverviewPromiseChain.then(this.fetchOverview);
      },
      immediate: true,
    },
    selectedMainCategory: {
      // comes from parent, even if it was initiated by clicking a tab in this component
      // (parent component sets/reads URL)
      handler: "onSelectedMainCategory",
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "@teliads/components/foundations/typography/mixins";
@import "@teliads/components/foundations/spacing/variables";

.overview-categories {
  .telia-fieldset > legend {
    @include telia-paragraph-100;
    margin-bottom: $telia-spacing-16;
  }
}
</style>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.overview-categories {
  &--error {
    margin-top: $telia-spacing-16;
  }

  &--loading,
  &--success {
    margin-top: calc(#{$telia-spacing-48} * -1);
  }

  &__skeleton-tabs {
    height: $telia-spacing-32;
    margin-bottom: $telia-spacing-48;
    display: flex;
    justify-content: space-between;
  }

  &__skeleton-tab {
    height: 100%;
    width: 100%;
    margin: 0 $telia-spacing-4;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
