/**
 * Makes the browser open a different page using POST.
 * Creates a temporary, hidden form, which is submitted and then removed.
 * Maybe there are better ways to do this?
 * @param dataName The name of the hidden input field - this is sent to backend
 * @param data The data object to pass. Will be serialized to JSON and sent as text to backend
 * @param targetUrl The URL to POST to
 */

export const postToUrl = (targetUrl: string, dataName: string, data: unknown) => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = targetUrl;
  form.ariaHidden = "true";
  form.style.display = "none";

  const hiddenInput = document.createElement("input");
  hiddenInput.type = "hidden";
  hiddenInput.name = dataName;
  hiddenInput.value = JSON.stringify(data);

  form.appendChild(hiddenInput);
  document.body.appendChild(form);

  form.submit();

  form.remove();
};
