import { addTranslations, translate as t } from "@telia/b2b-i18n";
import en from "./locales/en.json";
import sv from "./locales/sv.json";
import { MainCategory } from "./mainCategory";
import { CategoryId } from "./categories";

addTranslations({ en, sv });

export const getTranslatedMainCategoryDisplayName = (id: keyof typeof MainCategory): string =>
  t(`mainCategory.${id}`);

export const getTranslatedSubCategoryDisplayName = (id: CategoryId | "ALL"): string =>
  t(`productCategory.${id}`);

export const getMainCategoryName = (mainCategoryId: string): string =>
  t(`mainCategory.${mainCategoryId}`);

export const getProductCategoryName = (productCategoryId: string): string =>
  t(`productCategory.${productCategoryId}`);
