<template>
  <article key="manage-overview-error" class="overview-error">
    <telia-grid>
      <telia-text-spacing class="overview-error__content">
        <img :src="image" class="overview-error__image" alt="" />
        <telia-heading variant="title-300" tag="h1" class="overview-error__heading">
          {{ t(heading) }}
        </telia-heading>
        <telia-p variant="preamble-100" class="overview-error__message" v-if="!errorIsNoItems">
          {{ t(message) }}
        </telia-p>
        <telia-button @click="onClick" variant="primary" v-if="!errorIsNoItems">
          {{ t("MANAGE_OVERVIEW.ERROR.RELOAD_PAGE") }}
        </telia-button>
      </telia-text-spacing>
    </telia-grid>
  </article>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";

import satelliteDishIcon from "../images/TC_Icon_Satellite_Dish.svg";
import cloudsIcon from "../images/TC_Icon_Clouds.svg";

export default {
  name: "overview-error",

  mixins: [translateMixin],

  props: {
    errorIsNoItems: { type: Boolean, default: false },
  },

  async created() {
    await translateSetup();
  },

  methods: {
    onClick(event) {
      this.$emit("reload-click", event);
    },
  },

  computed: {
    image() {
      return this.errorIsNoItems ? cloudsIcon : satelliteDishIcon;
    },
    heading() {
      return `MANAGE_OVERVIEW.ERROR.${this.errorIsNoItems ? 'NO_ITEMS' : 'GENERIC'}.HEADING`;
    },
    message() {
      return `MANAGE_OVERVIEW.ERROR.${this.errorIsNoItems ? 'NO_ITEMS' : 'GENERIC'}.MESSAGE`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.overview-error {
  margin: auto;
  padding: $telia-spacing-24 0;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    max-width: calc(#{$telia-spacing-16} * 10);
    margin-bottom: $telia-spacing-8;
  }
}
</style>
