<template>
  <telia-grid>
    <telia-row>
      <telia-col width="12" class="overview-title">
        <div class="overview-title__heading-organisation">
          <telia-heading
            v-if="organizationState !== 'LOADING'"
            tag="h1"
            variant="title-300"
            class="overview-title__heading"
          >
            {{
              t("MANAGE_OVERVIEW.TITLE", {
                for: organizationState === "SUCCESS" ? t("MANAGE_OVERVIEW.FOR") : "",
              })
            }}
            <b2x-inline-dropdown
              v-if="organizationState === 'SUCCESS'"
              :value="selectedOrganization"
              :options="JSON.stringify(organizationOptions)"
              icon-size="md"
              heading-element="telia-heading"
              heading-tag="h1"
              heading-variant="title-300"
              :preview-lower-case="previewOrganizationLowercase"
              @optionSelected="$emit('selected-organization', $event.detail)"
            ></b2x-inline-dropdown>
          </telia-heading>

          <telia-skeleton
            v-if="organizationState === 'LOADING'"
            class="overview-title__heading-skeleton"
          ></telia-skeleton>
        </div>

        <telia-button
          class="overview-title__onboarding-button"
          @click="$emit('opened-onboarding')"
          variant="tertiary-purple"
          size="sm"
        >
          {{ t("MANAGE_OVERVIEW.ONBOARDING.ONBOARDING_BUTTON_TEXT") }} &nbsp;
          <span class="overview-title__onboarding-text--underlined">
            {{ t("MANAGE_OVERVIEW.ONBOARDING.ONBOARDING_LINK") }}
          </span>
        </telia-button>

        <div class="overview-title__agreement">
          <div v-if="agreementsLoading" class="overview-title__agreement-skeletons">
            <telia-skeleton class="overview-title__agreement-skeleton"></telia-skeleton>
          </div>
          <telia-p v-else-if="hasAgreements" class="overview-title__agreement-selector">
            {{ t("MANAGE_OVERVIEW.SELECT_AGREEMENT") }}
            <b2x-inline-dropdown
              :value="selectedAgreement"
              :options="JSON.stringify(agreementsOptions)"
              icon-size="sm"
              heading-element="telia-p"
              @optionSelected="$emit('selected-agreement', $event.detail)"
            ></b2x-inline-dropdown>
          </telia-p>
        </div>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import STATE from "../state.enum";

export default {
  name: "overview-title",

  mixins: [translateMixin],

  props: {
    agreementsOptions: { type: Array },
    agreementsLoading: { type: Boolean },
    organizationOptions: { type: Array },
    organizationState: { type: String },
    selectedAgreement: { type: String },
    selectedOrganization: { type: String },
  },

  async created() {
    await translateSetup(["mybusiness"]);
  },

  computed: {
    hasAgreements() {
      // The first "ALL" item always exists and does not count
      // Value might be incorrect if agreementsLoading.
      return this.agreementsOptions?.length > 1;
    },
    previewOrganizationLowercase() {
      // hack to not display mid-sentence uppercase: "Products & services for All organizations"
      return this.selectedOrganization === "ALL" && this.organizationOptions?.length > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/breakpoints/mixins.scss";

.overview-title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "heading"
    "agreementSelector"
    "onboardingButton";
  align-items: center;
  padding-top: $telia-spacing-48;
  padding-bottom: $telia-spacing-48;

  @include telia-breakpoint("lg") {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "heading heading onboardingButton"
      "agreementSelector agreementSelector agreementSelector";
  }

  &__heading-organisation {
    grid-area: heading;
  }

  &__heading {
    display: flex;
  }

  &__onboarding-button {
    grid-area: onboardingButton;
    justify-self: start;

    @include telia-breakpoint("lg") {
      justify-self: end;
    }
  }

  &__onboarding-text--underlined {
    text-decoration: underline;
    text-underline-offset: 0.1rem;
  }

  &__heading-skeleton {
    height: 4.8rem;
    width: 100%;
    display: inline-flex;
    vertical-align: sub;
  }

  &__agreement {
    grid-area: agreementSelector;
  }

  &__agreement-selector {
    display: flex;
    height: $telia-spacing-48;
    padding-bottom: $telia-spacing-16;
  }

  &__agreement-skeletons {
    padding: $telia-spacing-12 $telia-spacing-0;
  }

  &__agreement-skeleton {
    height: $telia-spacing-24;
    width: calc(#{$telia-spacing-64} * 4);
  }
}
</style>
