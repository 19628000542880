/**
 * Used to keep temporary page states, so that it can be restored when user goes back or forward through browser
 * history, but is reset if the user uses e.g. a link to arrive to a "fresh" version of the page.
 * 
 * Recommended usage:
 * Call getState to get either the saved state, or default state.
 * When the state changes, call setState to save the state in the background. (await is optional)

 * Do NOT use for critical data - functions will always resolve, even on failures. A failed get will
 * return default value and a failed set will fail silently (but both will log errors to FE log)
*/

// The functions are sync mmostly to avoid issues with CancelablePromises.
// For consistency they probably should be async.

import { getHistoryState, replaceHistoryState } from "./history-wrapper";
import { logError } from "@telia/b2x-logging";

/**
 * Get a temporary state if there is one, otherwise returning default state.
 * If setState was recently called and has not resolved yet, this MAY return an older value.
 * It is recommended to keep the real time state locally.
 * @param id
 * @param defaultValue
 * @returns
 */
export const getState = (id: string, defaultValue: unknown): unknown => {
  try {
    const allStates = getHistoryState();
    if (!allStates || typeof allStates[id] === "undefined") {
      return defaultValue;
    } else {
      return allStates[id];
    }
  } catch (error) {
    logError("b2b-manage-overview", "Unexpected error getting state from history");
    return defaultValue;
  }
};

/**
 * Save a temporary state.
 * Waiting for this to resolve is not recommended, unless for special cases.
 * getState is not guaranteed to return the new value until setState has resolved.
 * @param stateId
 * @param newState The new value. NOTE: Setting it to undefined, will remove the value - future getState will return default value instead.
 *    Other falsy values will be saved and returned.
 *    The value cannot be too large - there is browser dependent max total size (for all combined states on a page)
 * @returns
 */
export const setState = (id: string, state: unknown): void => {
  // sorry for the confusion: The one history.state contains several of a page's "temporary states"

  try {
    let newAllStates = getHistoryState();
    if (!newAllStates) {
      newAllStates = {};
    }

    // if we pass a Vue ProxyObject (i.e. the object directly from e.g. a prop)
    // browser fails to clone some complex objects for some reason.
    // There is "toRaw", but that only "unproxy" the outer layer of the object.
    // Cloning this way first turns it into raw normal objects that browser can handle.
    newAllStates[id] = JSON.parse(JSON.stringify(state));

    replaceHistoryState(newAllStates);
  } catch (error) {
    // most browsers impose a limit in the MegaByte order of magnitute (1-16 MB)
    logError("b2b-manage-overview", "Failed to replace state in history (maybe too large?)");
  }
};
