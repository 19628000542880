//TODO: get the enums from b2x-table
// import { TableCellType } from "@telia/b2x-table/dist/types/components/table-cell/enums/table-cell-type";
// import { ExportType } from "@telia/b2x-table/src/components/table-export/enums/export-type";

export enum TableCellType {
  TEXT = "text",
  TRUNCATABLE_TEXT = "truncatable-text",
  TRUNCATABLE_FLEXIBLE_WIDTH_TEXT = "truncatable-flexible-width-text",
  LINK = "link",
  SSPA_LINK = "sspa-link",
  BUTTON = "button",
  BUTTON_TEXT = "button-text",
  BADGE = "badge",
  STATUS_BADGE = "status-badge",
  CHECKBOX = "checkbox",
  TOGGLE = "toggle",
}

export enum ExportType {
  xlsx = ".xlsx",
  csv = ".csv",
}
