export const MainCategory = {
  ALL: "ALL",
  TELEPHONY: "TELEPHONY",
  BROADBAND_AND_BUSINESS_NETWORK: "BROADBAND_AND_BUSINESS_NETWORK",
  SWITCH: "SWITCH",
  SERVICE_AND_OTHER: "SERVICE_AND_OTHER",
} as const;

/**
 * Gets all of the main categories, in the order they should be displayed
 */
export const getMainCategories = (
  includeAll: boolean
): { name: keyof typeof MainCategory; url: string }[] =>
  (
    (includeAll ? [{ name: MainCategory.ALL, url: "" }] : []) as {
      name: keyof typeof MainCategory;
      url: string;
    }[]
  ).concat([
    { name: MainCategory.TELEPHONY, url: "telefoni" },
    { name: MainCategory.BROADBAND_AND_BUSINESS_NETWORK, url: "bredband" },
    { name: MainCategory.SWITCH, url: "vaxel" },
    { name: MainCategory.SERVICE_AND_OTHER, url: "ovrigt" },
  ]);
