<template>
  <div class="overview-table" :class="[{ 'wide-table': isWideTable }]">
    <telia-grid>
      <telia-row>
        <telia-col width="12">
          <OverviewTableSkeleton
            v-if="tableState === 'LOADING_EVERYTHING' || forceSkeleton"
            :default-page-size="selectedPageSize"
            :is-wide-table="isWideTable"
            :row-count="rowCount"
          />

          <OverviewControlledTable
            v-show="!forceSkeleton"
            ref="OverviewControlledTable"
            :selected-main-category="selectedMainCategory"
            :table-state="tableState"
            :columns="columns"
            :column-filter-context="columnFilterContext"
            :is-wide-table="isWideTable"
            :clear-filters-button-disabled="clearFiltersButtonDisabled"
            :row-count="rowCount"
            :data="data"
            :selectedPageSize="selectedPageSize"
            @toggleWideTable="onToggleWideTable"
            @filterColumnInput="onFilterColumnInput"
            @columnClick="onColumnClick"
            @columnManagerSelection="onColumnManagerSelection"
            @clearAllFilters="onClearAllFilters"
            @resetColumns="onResetColumns"
            @checkboxSelect="onCheckboxSelect"
            @exportData="onExportData"
          />

          <b2x-paginator
            :key="getPaginationKey()"
            v-show="shouldShowPagination"
            :page-sizes="JSON.stringify(pageSizes)"
            :list-length="rowCount"
            :default-page-size="selectedPageSize"
            :page-number="selectedPageNumber"
            @paginationChange="onPaginationChange"
          />
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script>
import {
  getPersistedState,
  setPersistedState,
  getTemporaryState,
  setTemporaryState,
} from "../services/state-service";
import { logError } from "@telia/b2x-logging";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import debounce from "p-debounce";

import OverviewControlledTable from "./OverviewControlledTable";
import OverviewTableSkeleton from "./OverviewTableSkeleton";
import OverviewError from "./OverviewError";

import { translateMixin } from "../locale";
import TableManager from "../helpers/TableManager";
import STATE from "../state.enum"; // simplified state to report to parent components
import TABLE_STATE from "././TableState.enum"; // state for this component and some child components

import { getSubscriptions, postExport } from "../services/subscription-service";

const COLUMN_FILTERS_ID = "COLUMN_FILTERS";

const IS_WIDE_TABLE_ID = "wideTable";
const PAGE_SIZE_ID = "pageSize";

const PAGE_NUMBER_ID = "PAGE_NUMBER";
const SORT_ID = "SORT";

const defaultPageSize = 10;

export default {
  name: "overview-table",

  mixins: [translateMixin],

  components: {
    OverviewControlledTable,
    OverviewTableSkeleton,
    OverviewError,
  },

  props: {
    // bundle scopeId, selectedAgreement/Organization/MainCategory/subCategories
    // in a single object to simplify change handling
    selection: {
      type: Object,
      required: false
    },

    // purely visual thing. If true, component will visually show skeletons as if still loading,
    // overriding everything else, even if it finished loading. This is to avoid
    // showing full table with "0 of 0 items" while overview categories are still loading.
    forceSkeleton: {
      type: Boolean,
      required: false,
    }
  },

  data: () => {
    const tableManager = new TableManager();

    return {
      tableManager,
      data: [],
      columns: [],

      // NO: including this in data makes it reactive, which breaks cancel etc.
      // fetchInstalledBasePromise: null,

      tableState: TABLE_STATE.LOADING_EVERYTHING,

      isWideTable: false,
      pageSizes: [10, 25, 50, 100],
      selectedPageSize: defaultPageSize,
      selectedPageNumber: null,

      // number of items matching the current category, filter etc, but does NOT reflect paging 
      rowCount: 0,

      columnFilter: {},
      columnFilterContext: 0,
      sort: null, // { column: ..., order: 'ASC/DESC' }
      shouldShowPagination: false,

      // these come from the selection object
      scopeId: null,
      selectedAgreement: null,
      selectedOrganization: null,
      selectedMainCategory: null,
      selectedSubCategories: null,

      selectionHandlingPromiseQueue: Promise.resolve(), // starting point for promise chain
    };
  },

  async mounted() {
    // instead of setting default value in "data" (which makes it reactive, which breaks things).
    // in composition API, this is like setting it to null instead of ref(null)
    this.fetchInstalledBasePromise = null;
  },

  created() {
    this.trackFiltersInput = debounce(this.trackFiltersInput, 4000);
  },

  methods: {
    columnIsCheckedByTitle(columns, title) {
      return columns.some((column) => {
        const attribute = this.tableManager.findAttributeByColumnTitle(column.title);
        return title === attribute && column.isChecked;
      });
    },

    resetFilters() {
      this.columnFilter = {};
      this.columnFilterContext++; // any pending debounced filter input will be discarded
      setTemporaryState(COLUMN_FILTERS_ID, this.columnFilter);
    },
    
    ensurePageNumber() {
      // If we load it too early, it becomes reset to 0 by watch handlers
      // Also we don't want to load it multiple times, since it SHOULD be reset to 0 when user change category

      if (this.selectedPageNumber === null) {
        this.selectedPageNumber = getTemporaryState(PAGE_NUMBER_ID, 1);
      }
    },

    resetPageNumberIfSet() {
      // Reset to 1 (show first page), ONLY IF SET (not null).
      // If we reset it when null, it breaks ensurePageNumber.
      if (this.selectedPageNumber !== null) {
        this.selectedPageNumber = 1; // first page is 1, not 0
        setTemporaryState(PAGE_NUMBER_ID, this.selectedPageNumber);
      }
    },

    async fetchTable(entireTable, resetPageNumberIfSet) {
      if (
        !this.scopeId ||
        !this.selectedOrganization ||
        !this.selectedAgreement ||
        !this.selectedMainCategory ||
        !this.selectedSubCategories?.length > 0
      ) {
        return; // do nothing, keep showing as loading until more properties are set
      }

      if (resetPageNumberIfSet) {
        this.resetPageNumberIfSet();
      }

      this.fetchInstalledBasePromise?.cancel?.();

      if (this.tableState !== TABLE_STATE.LOADING_EVERYTHING) {
        // avoid changing from "LOADING_EVERYTHING" to "LOADING_CONTENT"
        // (it may cause wrong/old conntrolledtable header to show)
        this.tableState = entireTable ? TABLE_STATE.LOADING_EVERYTHING : TABLE_STATE.LOADING_CONTENT;
      }

      this.ensurePageNumber();

      this.fetchInstalledBasePromise = getSubscriptions(
          this.scopeId,
          this.selectedOrganization,
          this.selectedAgreement,
          this.selectedMainCategory,
          this.selectedSubCategories,
          this.columnFilter,
          this.sort,
          this.selectedPageNumber,
          this.selectedPageSize,
        );

      // a new promise will not be cancellable, so we cannot tack it on to fetchInstalledBasePromise
      try {
        const installedbase = await this.fetchInstalledBasePromise;
        this.data = installedbase.subscriptions.map((subscription) =>
          this.tableManager.getTableRowFromSubscription(
            this.selectedMainCategory,
            subscription,
            this.scopeId
          )
        );

        this.rowCount = installedbase.filteredCount;

        this.tableState = TABLE_STATE.SUCCESS;

        // start showing pagination, keep it showing forever
        this.shouldShowPagination = true;

        if (!entireTable) {
          this.$nextTick(() => {
            this.trackSearchResults();
          });
        }
      } catch (error) {
        if (error.isCancelled) {
          return; // do nothing, keep showing load state etc (newer promise that cancelled it will take over)
        } else {
          logError('b2b-manage-overview', 'Failed to fetch subscriptions')
          this.tableState = TABLE_STATE.ERROR;
        }
      }
    },
    updateColumns() {
      this.columns = this.tableManager.getTableColumns(this.selectedMainCategory, this.sort);
    },
    onToggleWideTable() {
      this.isWideTable = !this.isWideTable;

      setPersistedState(this.scopeId, IS_WIDE_TABLE_ID, this.isWideTable);

      const trackingLabel = this.isWideTable ? analytics.action.EXPAND : analytics.action.MINIMIZE;
      analytics.trackEvent(analytics.category.MANAGE, analytics.action.CLICK, trackingLabel);
    },
    resetSort() {
      this.sort = this.tableManager.getDefaultSort(this.selectedMainCategory);
    },
    getPaginationKey() {
      let selectedMainCategory = this.selectedMainCategory || "";
      let selectedSubCategory = this.selectedSubCategories?.join() || "";
      let key = `${selectedMainCategory}-${selectedSubCategory}`;

      return key;
    },
    trackFiltersInput(attribute) {
      analytics.trackEvent(analytics.category.MANAGE, analytics.action.FILTER, attribute);
    },
    trackSearchResults() {
      const hasSelectedSubCatgories = !this.selectedSubCategories.includes("ALL");
      const hasColumnFilters = Object.keys(this.columnFilter).length > 0;
      const trackingLabel = `Total items: ${this.data.length} of ${this.rowCount}`;

      if (hasSelectedSubCatgories || hasColumnFilters) {
        analytics.trackEvent(
          analytics.category.MANAGE,
          analytics.action.SEARCH_RESULTS,
          trackingLabel
        );
      }
    },

    async onSelectionChange() {
      // This method should not be called concurrently. new changes are "queued" by the caller.
      // Parent should not change selection rapidly - there should be one initial selection,
      // then one change for each user interaction.
      const arraysAreEqual = (a1, a2) => {
        if (!a1 && !a2) {
          return true;
        } else if ((a1 && !a2) || (!a1 && a2)) {
          return false;
        } else {
          return a1?.length === a2?.length && a1.every((v,i)=> v === a2[i]);
        }
      };

      if (
        !(
          this.selection?.scopeId &&
          this.selection?.agreement &&
          this.selection?.organization &&
          this.selection?.mainCategory &&
          this.selection?.subCategories?.length > 0
        )) {
        // selection incomplete, keep showing skeleton
        return;
      }

      let needContentFetchTable = false;
      let needEntireFetchTable = false;

      let firstLoad;

      if (this.scopeId !== this.selection.scopeId) {
        if (this.scopeId) {
          logError('b2b-manage-overview', 'ScopeId changed after initial setup. New value ignored.');
        } else { 
          this.scopeId = this.selection.scopeId;
          // this is basically "initial setup".
          // we do not handle changing scopeId more than once (from null to the real value) 

          firstLoad = true;

          // loadIsWideTable only has superficial effects, no need to wait for it, it can finish whenever
          this.isWideTable = await getPersistedState(this.scopeId, IS_WIDE_TABLE_ID, false);

          this.selectedPageSize = await getPersistedState(
            this.scopeId,
            PAGE_SIZE_ID,
            defaultPageSize
          );
        }
        needEntireFetchTable = true;
        await this.tableManager.fetchSettings(this.scopeId);
      }

      if (this.selectedAgreement !== this.selection.agreement) {
        this.selectedAgreement = this.selection.agreement
        // no TECHNICAL reason for just refreshing content,
        // but it might be replaced by "there are no items", so awkward to display it
        needEntireFetchTable = true;
      }

      if (this.selectedOrganization !== this.selection.organization) {
        this.selectedOrganization = this.selection.organization
        // no TECHNICAL reason for just refreshing content,
        // but it might be replaced by "there are no items", so awkward to display it
        needEntireFetchTable = true;
      }

      if (this.selectedMainCategory !== this.selection.mainCategory) {
        this.selectedMainCategory = this.selection.mainCategory;
        needEntireFetchTable = true;

        if (firstLoad) {
          this.sort = getTemporaryState(
            SORT_ID,
            this.tableManager.getDefaultSort(this.selectedMainCategory)
          );
        } else {
          this.sort = this.tableManager.getDefaultSort(this.selectedMainCategory);
        }

        this.updateColumns();

        if (firstLoad) {
          this.columnFilter = getTemporaryState(COLUMN_FILTERS_ID, {});

          let convertedColumnFilter = Object.entries(this.columnFilter).map(
            ([key, value]) => ({
              "column": this.tableManager.findColumnTitleByAttribute(key),
              "inputValue": value
            })
          );

          this.$refs.OverviewControlledTable.setFilters(convertedColumnFilter);
        } else {
          this.resetFilters();
          this.$refs.OverviewControlledTable.clearAllFilters();
        }
      }

      if (!arraysAreEqual(this.selectedSubCategories, this.selection.subCategories)) {
        // clone it, otherwise parent changes will bypass it 
        this.selectedSubCategories = [...this.selection.subCategories];

        needContentFetchTable = true;
      }

      // this table call is async, and we do not wait for it. It can be ran concurrently.
      if (needEntireFetchTable) {
        this.fetchTable(true, true);
      } else if (needContentFetchTable) {
        this.fetchTable(false, true);
      } // else - nothing changed, no table fetch required
    },
    onColumnClick(columnTitle) {
      // which column is selected?
      const attribute = this.tableManager.findAttributeByColumnTitle(columnTitle);

      if (this.sort.column === attribute) {
        // clicked the same column - reverse it
        this.sort = {
          column: attribute,
          direction: this.sort.direction === "ASC" ? "DESC" : "ASC",
        };
      } else {
        // clicked a different column
        this.sort = {
          column: attribute,
          direction: "ASC",
        };
      }
      this.updateColumns(); // needed because new this.sort

      // these last things are all async and done in parallell
      this.fetchTable(false, false);
      setTemporaryState(SORT_ID, this.sort);
      analytics.trackEvent(analytics.category.MANAGE, analytics.action.SORT, attribute);
    },
    onFilterColumnInput(detail) {
      const { column, value, automatic } = detail;
      const trimmedValue = value.trim();

      const attribute = this.tableManager.findAttributeByColumnTitle(column);

      let filtersChanged = false;

      if (trimmedValue?.length > 0) {
        // more than one character: add/replace the filter
        this.columnFilter = {
          ...this.columnFilter,
          [attribute]: trimmedValue,
        };
        this.trackFiltersInput(attribute); // this one is debounced
        filtersChanged = true;
      } else if (this.columnFilter[attribute]) {
        // a filter was changed to an empty value - remove it from the list of filters
        const columnFilter = { ...this.columnFilter };
        delete columnFilter[attribute];

        this.columnFilter = columnFilter;
        filtersChanged = true;
      } // else filters didn't really change, do nothing

      if (filtersChanged) {
        setTemporaryState(COLUMN_FILTERS_ID, this.columnFilter);
        this.fetchTable(false, !automatic); // note - async!
      }
    },
    onPaginationChange(event) {
      const { page, pageSize } = event.detail;

      let anythingChanged = false;

      if (this.selectedPageSize !== pageSize) {
        anythingChanged = true;
        this.selectedPageSize = pageSize;
        setPersistedState(this.scopeId, PAGE_SIZE_ID, this.selectedPageSize);
      }

      if (this.selectedPageNumber != page) {
        anythingChanged = true;
        this.selectedPageNumber = page;
        setTemporaryState(PAGE_NUMBER_ID, this.selectedPageNumber);
      }

      if (anythingChanged) {
        this.fetchTable(false, false); // note - async!
      }
    },
    onColumnManagerSelection(columns) {
      // event is triggered with 0-length array during loading, for some reason
      // Triggered either when we change the column by changing main category etc, or when user change column settings.
      if (columns.length > 0) {
        const isSortVisible = this.columnIsCheckedByTitle(columns, this.sort.column);

        if (this.tableState !== TABLE_STATE.LOADING_EVERYTHING) {
          // assume the event was triggered by changing main category etc, no need to save/update it.
          this.tableManager.setCategoryColumns(this.scopeId, this.selectedMainCategory, columns);
        }

        if (!isSortVisible) {
          this.resetSort();
        }

        // remove all those filters that were applied for all columns that were removed
        // we only need to change this.columnFilter - b2x-table automatically forgets the filters
        let filtersChanged = false;
        for (const columnFilterTitle of Object.keys(this.columnFilter)) {
          if (!this.columnIsCheckedByTitle(columns, columnFilterTitle)) {
            filtersChanged = true;
            delete this.columnFilter[columnFilterTitle];
          }
        }

        this.updateColumns(); // updates this.columns to reflect new category columns AND (if any) new sorting

        if (!isSortVisible || filtersChanged) {
          // reloading table is only needed if sorting or filters were affected 
          this.fetchTable(false, false); // note - async!
        }
      }
    },
    onClearAllFilters() {
      // other events are triggered for the clearing of the columns
      this.resetSort();

      // clearing this here is not REALLY needed, we get events from OverviewControlledTable that clears all the filters separately.
      // problem is they are debounced, so are done later than resetSort.
      this.resetFilters();

      this.updateColumns(); // needed because new this.sort

      // the last two things are async and done in parallell
      this.fetchTable(false, false);
      analytics.trackEvent(analytics.category.MANAGE, analytics.action.CLICK, "Reset filters");
    },
    onResetColumns() {
      this.$nextTick(() => {
        this.tableManager.resetCategoryColumns(this.scopeId, this.selectedMainCategory);

        this.updateColumns();

        analytics.trackEvent(analytics.category.MANAGE, analytics.action.CLICK, "Reset columns");
      });
    },
    onCheckboxSelect(detail) {
      // only tracking - onColumnManagerSelection is also triggered for the actual column change
      const addedColumn = !this.columns.find((column) => column.title === detail).isChecked;
      const trackingCategory = analytics.category.MANAGE;
      const trackingAction = addedColumn
        ? analytics.action.ADD_COLUMN
        : analytics.action.REMOVE_COLUMN;
      const trackingLabel = `Key: [${this.tableManager.findAttributeByColumnTitle(detail)}]`;

      analytics.trackEvent(trackingCategory, trackingAction, trackingLabel);
    },
    async onExportData(exportType) {
      const mapColumn = (column) => {
        const attribute = this.tableManager.findAttributeByColumnTitle(column.title);
        let valueLabels = undefined;
        if (attribute === "status") {
          // hardcoded special case for the status column
          // the values and labels are defined in b2b-subscription-common -> getTranslatedAndSortedStatuses
          // (but the sorting is irrelevant)

          valueLabels = column.filterable.values.map((valueLabelPair) => ({
            value: valueLabelPair.value,
            label: valueLabelPair.displayName
          }));
        }
        return {
          attribute: attribute,
          label: column.title,
          valueLabels: valueLabels,
        }
      };

      const columns = this.columns
        .filter((column) => column.isChecked)
        .map(column => mapColumn(column));

      analytics.trackEvent(
        analytics.category.MANAGE,
        analytics.action.EXPORT_INITIATED,
        `Format ${exportType} |
      Total items: ${this.selectedPageSize} of ${this.rowCount} |
      Key: ${columns.map((column) => column.attribute).join("_")}`
      );

      postExport(
        this.scopeId,
        exportType,
        this.selectedOrganization,
        this.selectedAgreement,
        this.selectedMainCategory,
        this.selectedSubCategories,
        this.columnFilter,
        this.sort,
        columns
      )
    },
    onTableStateChange() {
      // triggered when tableState changed - notify parents
      let state;

      if (this.tableState === TABLE_STATE.LOADING_EVERYTHING || this.tableState === TABLE_STATE.LOADING_CONTENT) {
        state = STATE.LOADING;
      } else { // "ERROR" or "SUCCESS"
        state = this.tableState;
      }

      // EMIT "LOADING", "SUCCESS" or "ERROR"
      // parent uses that to show error if there is one
      this.$emit("table-state", state);
    },
  },

  computed: {
    clearFiltersButtonDisabled() {
      const defaultSort = this.tableManager.getDefaultSort(this.selectedMainCategory);

      return !(this.sort && (
        Object.keys(this.columnFilter).length > 0 ||
        defaultSort.column !== this.sort.column ||
        defaultSort.direction !== this.sort.direction
      ));
    },
  },

  watch: {
    selection: {
      handler() {
        // wait for all previous changes to complete before starting the next
        this.selectionHandlingPromiseQueue = this.selectionHandlingPromiseQueue.then(this.onSelectionChange);
      },
      deep: true,
      immediate: true,
    },
    
    tableState: {
      // triggered only by fetchTable. only emits the new state to parent
      handler: "onTableStateChange",
    },
    // end of data props
  },
};
</script>

<style lang="scss" scoped>
.overview-table telia-grid {
  transition: max-width 0.3s ease-in;
}
.overview-table.wide-table telia-grid {
  max-width: 100%;
}
</style>
