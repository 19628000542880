<template>
  <transition name="fade">
    <div class="overview-navigation" v-if="links.length > 0">
      <b2x-sspa-link
        v-for="link in links"
        :key="link.href"
        :href="link.href"
        variant="standalone"
        class="overview-navigation__link"
      >
        <telia-icon
          :name="link.icon"
          size="sm"
          slot="left"
          class="overview-navigation__link-icon"
        ></telia-icon>
        <span>{{ link.label }}</span>
      </b2x-sspa-link>
    </div>
  </transition>
</template>

<script>
import {
  alarm as alarmIcon,
  alert as alertIcon,
  barChart as barChartIcon,
  delivery as deliveryIcon,
  error as errorIcon,
  server as serverIcon,
} from "@teliads/icons";

import { translateSetup, translateMixin } from "../locale";

import { logError } from "@telia/b2x-logging";
import { hasPermission } from "@telia/b2b-logged-in-service";
import { useFlag } from "@unleash/proxy-client-vue";
import { FeatureFlags } from "../feature-flags/featureFlags";

export default {
  name: "overview-navigation",
  mixins: [translateMixin],

  props: {
    scopeId: { type: String, required: true },
    subCategories: { type: Array, default: () => [] },
    selectedSubCategories: { type: Array, default: () => [] },
    isBroadbandAndBusinessNetworkProductAvailable: { type: Boolean, default: false },
  },

  setup() {
    const isManageNotificationsEnabled = useFlag(FeatureFlags.NOTIFICATIONS_CENTER);
    return {
      isManageNotificationsEnabled,
    };
  },

  data: () => ({
    hasReadVpnPermission: false,
    hasReadDatacomNotificationsPermission: false,
    hasReadDatacomDeliveriesPermission: false,
    hasManageNotificationsCenterPermission: false,
  }),

  async created() {
    translateSetup();

    this.hasReadVpnPermission = await this.getPermission("READ_VPN");
    this.hasReadDatacomNotificationsPermission = await this.getPermission(
      "READ_DATACOM_NOTIFICATIONS"
    );
    this.hasReadDatacomDeliveriesPermission = await this.getPermission("READ_DATACOM_DELIVERIES");
    this.hasManageNotificationsCenterPermission = await this.getPermission(
      "MANAGE_NOTIFICATIONS_CENTER"
    );
  },

  computed: {
    links() {
      return [
        {
          visibleForSubCategories: ["DATACOM"],
          permission: this.hasReadDatacomDeliveriesPermission,
          subUrl: "/produkter-tjanster/datanet-vpn/leveranser",
          translationKey: "MANAGE_OVERVIEW.NAVIGATION.DATACOM_DELIVERIES",
          icon: deliveryIcon,
        },
        {
          visibleForSubCategories: ["DATACOM"],
          permission: this.hasReadVpnPermission,
          subUrl: "/produkter-tjanster/datanet-vpn/vpn-administration",
          translationKey: "MANAGE_OVERVIEW.NAVIGATION.VPN_ADMINISTRATION",
          icon: serverIcon,
        },
        {
          visibleForSubCategories: ["DATACOM"],
          permission: this.hasReadDatacomNotificationsPermission,
          subUrl: "/produkter-tjanster/datanet-vpn/notifieringar",
          translationKey: "MANAGE_OVERVIEW.NAVIGATION.NOTIFICATIONS",
          icon: alertIcon,
        },
        {
          visibleForSubCategories: ["MOBILE_M2M", "FUNCTION_MOBILE"],
          permission: true,
          subUrl: "/overvakning-larm",
          translationKey: "MANAGE_OVERVIEW.NAVIGATION.M2M_ALARM",
          icon: errorIcon,
        },
        {
          visibleForSubCategories: ["MOBILE_M2M"],
          permission: true,
          subUrl: "/overvakning-trafik",
          translationKey: "MANAGE_OVERVIEW.NAVIGATION.M2M_TRAFFIC_STATISTICS",
          icon: barChartIcon,
        },
        ...(this.isManageNotificationsEnabled
          ? [
              {
                visibleForSubCategories: ["DATACOM"],
                permission: this.isBroadbandAndBusinessNetworkProductAvailable,
                subUrl: `/produkter-tjanster/notifieringar${
                  this.hasManageNotificationsCenterPermission ? "" : "/anvandare"
                }`,
                translationKey: "MANAGE_OVERVIEW.NAVIGATION.NOTIFICATION_SETTINGS",
                icon: alarmIcon,
              },
            ]
          : []),
      ]
        .filter(
          (link) =>
            link.permission &&
            // the current "main" category contains the required subcategory
            this.subCategories.some((availableSubCategory) =>
              link.visibleForSubCategories.includes(availableSubCategory.name)
            ) &&
            // the required subcategory is implicitly selected through "ALL"
            (this.selectedSubCategories.includes("ALL") ||
              // the required subcategory is explicitly selected
              this.selectedSubCategories.some((selectedSubCategory) =>
                link.visibleForSubCategories.includes(selectedSubCategory)
              ))
        )
        .map((link) => ({
          href: `/foretag/mybusiness/${this.scopeId}/hantera${link.subUrl}`,
          label: this.t(link.translationKey),
          icon: link.icon.name,
        }));
    },
  },
  methods: {
    async getPermission(permission) {
      try {
        return await hasPermission(permission);
      } catch {
        logError(
          "b2b-manage-overview",
          "Failed to check permission if category links should be displayed"
        );
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/borders/mixins";
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/motion/variables";
@import "@teliads/components/foundations/spacing/variables";

.overview-navigation {
  padding: $telia-spacing-16 0;
  display: flex;
  justify-content: flex-start;
  gap: $telia-spacing-32;
  flex-wrap: wrap;
  @include telia-border("gray-500", "xs", "bottom");

  &__link-icon {
    vertical-align: middle;
    margin-right: $telia-spacing-8;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all $telia-ease-in-out $telia-duration-100;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  margin-top: calc(#{$telia-spacing-32} * -1);
}
</style>
