<template>
  <div class="overview-table-skeleton">
    <div class="overview-table-skeleton__skeletons">
      <telia-skeleton class="overview-table-skeleton__row-count"></telia-skeleton>

      <div class="overview-table-skeleton__actions">
        <b2x-table-controls-button
          :a11y-label="t('mybusiness.clearAllFilters')"
          :button-text="t('mybusiness.clearAllFilters')"
          data-test-id="clear-all-filters"
          is-disabled
        ></b2x-table-controls-button>

        <div class="overview-table-skeleton__actions-right">
          <b2x-table-controls-button
            icon-name="download"
            :a11y-label="t('MANAGE_OVERVIEW.TABLE.EXPORT_BUTTON_LABEL')"
            :button-text="t('MANAGE_OVERVIEW.TABLE.EXPORT_BUTTON_LABEL')"
            data-test-id="export-button"
            is-disabled
            class="overview-table-skeleton__export-button"
          ></b2x-table-controls-button>

          <b2x-table-controls-button
            icon-name="sorter"
            :a11y-label="t('mybusiness.columnManager')"
            :button-text="t('mybusiness.columnManager')"
            data-test-id="column-manager"
            is-disabled
            class="overview-table-skeleton__column-manager"
          ></b2x-table-controls-button>

          <b2x-table-controls-button
            :a11y-label="toggleTableWidthButtonText"
            data-test-id="expand-button"
            is-disabled
            class="overview-table-skeleton__expand-button"
          >
            <div class="expand-icon-wrapper" :class="{ 'minimized': !isWideTable, 'expanded': isWideTable }">
              <telia-icon
                name="arrow-left"
                size="sm"
                class="left"
              ></telia-icon>
              <telia-icon
                name="arrow-right"
                size="sm"
                class="right"
              ></telia-icon>
              <span> {{ toggleTableWidthButtonText }} </span>
            </div>
          </b2x-table-controls-button>
        </div>
      </div>
    </div>

    <b2x-table-skeleton :rows="skeletonRows" columns="6"></b2x-table-skeleton>
  </div>
</template>

<script>
import { translateMixin } from "../locale";

export default {
  name: "overview-table-skeleton",

  mixins: [translateMixin],

  props: {
    defaultPageSize: { type: Number, default: 10 },
    isWideTable: { type: Boolean, default: false },
    rowCount: { type: Number, default: 0 },
  },

  computed: {
    skeletonRows() {
      return Math.min(this.rowCount || this.defaultPageSize, this.defaultPageSize);
    },
    toggleTableWidthButtonText() {
      return this.isWideTable
        ? this.t("MANAGE_OVERVIEW.TABLE.COMPACT")
        : this.t("MANAGE_OVERVIEW.TABLE.WIDE");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/breakpoints/mixins";

.overview-table-skeleton {
  &__skeletons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $telia-spacing-12;
  }

  &__row-count {
    width: calc(#{$telia-spacing-128} + #{$telia-spacing-32});
    height: $telia-spacing-24;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__actions-right {
    display: flex;
  }

  &__expand-button {
    display: none;
    visibility: hidden;
    margin-left: $telia-spacing-8;

    @include telia-breakpoint("xl") {
      display: flex;
      visibility: visible;
    }
  }

  .expand-icon-wrapper {
    height: 2rem;
    display: flex;
    align-items: center;

    .telia-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
    }

    &.expanded {
      .left {
        transform: translate(-0.6rem, 0.2rem) rotate(135deg);
      }
      .right {
        transform: translate(-1rem, -0.6rem) rotate(135deg);
      }
    }

    &.minimized {
      .left {
        transform: translate(-0.5rem, 0.3rem) rotate(-45deg);
      }
      .right {
        transform: translate(-0.9rem, -0.5rem) rotate(-45deg);
      }
    }
  }
}
</style>
