<template>
  <b2x-onboarding
    :is-open="open"
    @closeModal="onCloseModal"
    @nextStepClick="trackOnboardingNextStep"
    @previousStepClick="trackOnboardingPreviousStep"
  >
    <b2x-onboarding-step
      :key="step.title"
      v-for="step in steps"
      v-bind="step"
    ></b2x-onboarding-step>
  </b2x-onboarding>
</template>

<script>
import { onboardingVueMixin as onbardingAnalyticsMixin } from "@telia/b2b-web-analytics-wrapper";
import { translateSetup, translateMixin } from "../locale";

import onboarding_1 from "../images/onboarding_1.gif";
import onboarding_2 from "../images/onboarding_2.gif";
import onboarding_3 from "../images/onboarding_3.gif";
import onboarding_4 from "../images/onboarding_4.gif";

export default {
  name: "overview-onboarding",

  mixins: [translateMixin, onbardingAnalyticsMixin],

  props: {
    open: { type: Boolean, default: false },
  },

  async created() {
    translateSetup();
  },

  methods: {
    onOpenStateChange() {
      if (this.open) {
        this.trackOnboardingOpen();
      }
    },
    onCloseModal({ detail }) {
      window.localStorage.setItem("manageOnboardingWatched", "1");
      this.$emit("change", false);

      this.trackOnboardingClose(detail.activeStep, detail.numberOfSteps);
    },
  },

  computed: {
    steps() {
      return [
        {
          src: onboarding_1,
          heading: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_1.HEADING"),
          description: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_1.DESCRIPTION"),
          alt: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_1.ALT"),
        },
        {
          src: onboarding_2,
          heading: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_2.HEADING"),
          description: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_2.DESCRIPTION"),
          alt: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_2.ALT"),
        },
        {
          src: onboarding_3,
          heading: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_3.HEADING"),
          description: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_3.DESCRIPTION"),
          alt: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_3.ALT"),
        },
        {
          src: onboarding_4,
          heading: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_4.HEADING"),
          description: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_4.DESCRIPTION"),
          alt: this.t("MANAGE_OVERVIEW.ONBOARDING.STEP_4.ALT"),
        },
      ];
    },
  },

  watch: {
    open: "onOpenStateChange",
  },
};
</script>
