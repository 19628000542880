// this just wraps history.state so that we can easily mock it in Jest. maybe there are better ways..

export const getHistoryState = () => history.state;

export const replaceHistoryState = (newState) => {
  history.replaceState(
    newState,
    "" // // name of state, Safari use it but ignored by most browsers, is obsolete and "" is recommended according to MDN.
  );
};
