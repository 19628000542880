import { logError } from "@telia/b2x-logging";

import { ExportType } from "../b2x-table.enum";

export const mapExportType = (exportType: string) => {
  switch (exportType) {
    case ExportType.csv:
      return "csv";
    case ExportType.xlsx:
      return "excel";
    default:
      logError("b2b-manage-overview", "Unexpected export type, defaulting to Excel");
      return "excel";
  }
};

export const mapRequestData = (
  selectedOrganization: string,
  selectedAgreement: string,
  selectedMainCategory: string,
  selectedSubCategories: Array<string>,
  columnFilter: Record<string, string>,
  sort: { column: string; direction: "ASC" | "DESC" }
) => {
  return {
    tscidsFilter: selectedOrganization === "ALL" ? [] : [selectedOrganization],
    agreementsFilter: selectedAgreement === "ALL" ? [] : [selectedAgreement],
    mainCategory: selectedMainCategory,
    subCategories: selectedSubCategories,
    columnFilter: columnFilter,
    sort: sort,
  };
};
