/**
 * Use to save state or user preferences for up to a few months. The data is tied to a the SCOPE, not the
 * browser or user. The data is stored in corp-miscellaneous-data and is removed after a few months of not being read.
 * 
 * Recommended usage:
 * Optionally call prefetchStates (no await needed) as early as possible to get the states from backend.
 *    (If not, first getState will do that automatically.)
 * Call getState to get either the saved state, or default state. Keep the state locally.
 * When the state changes, call setState to save the state in the background. (no await needed)

 * Do NOT use for critical data - functions will always resolve, even on backend errors. A failed get will
 * return default value and a failed set will fail silently (but both will log errors to FE log)
 * 
 * Each scopeId is a different "world" - e.g. prefetchStates(scopeId_one) will not benefit getState(scopeId_two).
*/

import { corpMiscellaneousData } from "@telia/b2b-rest-client";
import { ApiError } from "@telia/b2b-rest-client/dist/corp-miscellaneous-data";
import debounce from "p-debounce";
import { logError } from "@telia/b2x-logging";

const PERSISTED_DATA_VERSION = "1"; // single version, maintain backwards-compatible models for now

// If we reuse this service elsewhere, move this ID to the consumer somehow! (Constructor, extra parameter?)
const PERSISTED_DATA_KEY = "MANAGE_OVERVIEW_TABLE";

// one promise for each scope ID
const initialLoadStatePromises: Record<string, Promise<void>> = {};

// one item for each scope ID, one inner item for each id
const allStates: Record<string, Record<string, unknown>> = {};

const unprotectedPrefechStates = async (scopeId: string): Promise<void> => {
  let statesForScope: Record<string, unknown>;
  let status = "DEFAULT";
  try {
    // the current rest client has an incorrect response definition
    // not possible to regenerate it at the moment because swagger file is broken
    const responseBody = (await corpMiscellaneousData.PublicControllerService.getData(
      scopeId,
      PERSISTED_DATA_KEY,
      PERSISTED_DATA_VERSION
    )) as { status?: string; data?: unknown };

    // this status is NOT the HTTP status - getData body has a custom status property.
    status = responseBody.status ?? "UNDEFINED_FROM_BE";

    if (responseBody.status == "OK") {
      statesForScope = (responseBody.data as Record<string, unknown>) || {};
    } else if (responseBody.status === "NOT_FOUND") {
      statesForScope = {};
    } else {
      throw new Error("Unexpected status");
    }
  } catch (error) {
    // if an error happened in getData, an ApiError is thrown where status is the HTTP status. 
    if (error && (error as ApiError).status === 401) {
      logError(
        "b2b-manage-overview",
        `Failed to load states, probably because user has been logged out (401). status=${status}`
      );
    } else {
      logError(
        "b2b-manage-overview",
        `Failed to load states due to unexpected reason. status=${status}`
      );
    }
    statesForScope = {};
  }
  allStates[scopeId] = statesForScope;
};

/**
 * Optionally prefetches persisted state from backend, to reduce delay if it's needed later. Can be done in background, await is optional.
 * @param scopeId
 * @returns
 */
export const prefetchStates = async (scopeId: string): Promise<void> => {
  // one singleton for each scope ID, wait for previously existing promise if already triggered
  if (!initialLoadStatePromises[scopeId]) {
    initialLoadStatePromises[scopeId] = unprotectedPrefechStates(scopeId);
  }
  await initialLoadStatePromises[scopeId];
};

/**
 * Get a persisted state, loading it from backend if needed, and returning default state if it doesn't exist or an error happened.
 * If setState was recently called and has not resolved yet, this MAY return an older value.
 * It is recommended to keep the real time state locally.
 * @param scopeId
 * @param id
 * @param defaultState
 * @returns
 */
export const getState = async (
  scopeId: string,
  stateId: string,
  defaultState: unknown
): Promise<unknown> => {
  await prefetchStates(scopeId);
  if (typeof allStates[scopeId][stateId] === "undefined") {
    return defaultState;
  } else {
    return allStates[scopeId][stateId];
  }
};

const persistState = async (scopeId: string): Promise<void> => {
  // save
  let status = "DEFAULT";
  try {
    const responseBody = await corpMiscellaneousData.PublicControllerService.save(
      scopeId,
      PERSISTED_DATA_KEY,
      PERSISTED_DATA_VERSION,
      allStates[scopeId]
    );

    // this status is NOT the HTTP status - "save" body has a custom status property.
    status = responseBody.status ?? "UNDEFINED_FROM_BE";

    if (responseBody.status !== "OK") {
      throw new Error("Status not OK");
    }
  } catch (error) {
    // if an error happened in "save", an ApiError is thrown where status is the HTTP status. 
    if (error && (error as ApiError).status === 401) {
      logError(
        "b2b-manage-overview",
        `Failed to save state, probably because user has been logged out (401). status=${status}`
      );
    } else {
      logError(
        "b2b-manage-overview",
        `Failed to save state due to unexpected reason. status=${status}`
      );
    }
  }
};

const debouncedPersistState = debounce(persistState, 500);

/**
 * Save a persisted state.
 * A delay is included to avoid multiple calls to backend.
 * Waiting for this to resolve is not recommended, unless for special cases.
 * getState is not guaranteed to return the new value until setState has resolved.
 * It is highly recommended calling getState or prefetchStates and letting them resolve first.
 *    OTHERWISE, AND if multiple parallell setState calls are made with same id but different newState,
 *    any of the new values may end up being saved, not guaranteed to be the last one.
 * @param scopeId
 * @param stateId
 * @param newState The new value. NOTE: Setting it to undefined, will remove the value - future getState will return default value instead.
 *    Other falsy values will be saved and returned.
 * @returns
 */
export const setState = async (
  scopeId: string,
  stateId: string,
  newState: unknown
): Promise<void> => {
  await prefetchStates(scopeId);
  // undetermined result if prefetchStates has not completed already, and multiple setStates are lined up here simultaneously.
  // Add proper queue? Probably not worth it for the edge case it is needed for.

  // immediatelly save the state "locally" so that a get will give the new value right away
  allStates[scopeId][stateId] = newState;

  // save (debounce to reduce traffic)
  await debouncedPersistState(scopeId);
};
