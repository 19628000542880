<template>
  <div data-test-id="b2b-manage-overview" class="manage-overview">
    <OverviewOnboarding :open="modalOpen" @change="modalOpen = $event" />
    <div class="manage-overview__header">
      <OverviewTitle
        :agreements-options="agreements"
        :agreements-loading="agreementsLoading"
        :organization-options="organizations"
        :organization-state="organizationsState"
        :selected-agreement="selectedAgreement"
        :selected-organization="selectedOrganization"
        @opened-onboarding="openOnboardingModal"
        @selected-agreement="onSelectedAgreement"
        @selected-organization="onSelectedOrganization"
      />
    </div>

    <OverviewCategories
      :key="`overview-categories-${categoriesKey}`"
      :scope-id="scopeId"
      :selected-agreement="selectedAgreement"
      :selected-organization="selectedOrganization"
      :selected-main-category="selectedMainCategory"
      :selected-sub-categories="selectedSubCategories"
      :organizations-state="organizationsState"
      @categories-state="onCategoriesState"
      @selected-main-category="onSelectedMainCategory"
      @selected-sub-categories="onSelectedSubCategories"
      @currently-selected-main-category-has-items-changed="onSelectedMainCategoryHasItemsChanged"
    />

    <OverviewTable
      v-if="selectedMainCategoryHasItems !== 'HAS_NO_ITEMS'"
      v-show="!hasError"
      :force-skeleton="selectedMainCategoryHasItems === 'UNKNOWN'"
      :key="`overview-table-${tableKey}`"
      :selection="selectionForOverviewTable"
      @table-state="onTableState"
    />

    <OverviewError
      v-if="hasError || selectedMainCategoryHasItems === 'HAS_NO_ITEMS'"
      @reload-click="onReloadClick"
      :error-is-no-items="!hasError"
    />
  </div>
</template>

<script>
// common utilities
import {
  prefetchPersistedStates,
  getTemporaryState,
  setTemporaryState,
} from "./services/state-service";
import { logError } from "@telia/b2x-logging";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { match } from "path-to-regexp";
import { translateSetup, translateMixin } from "./locale";
import STATE from "./state.enum";

// load data
import { getScopeId } from "@telia/b2b-customer-scope";
import { getOrganizations } from "./services/organization-service";
import { getAgreements } from "./services/agreement-service";
import { getMainCategories } from "@telia/b2b-product-categories-lib";

// subcomponents
import OverviewTable from "./components/OverviewTable";
import OverviewTitle from "./components/OverviewTitle";
import OverviewCategories from "./components/OverviewCategories";
import OverviewOnboarding from "./components/OverviewOnboarding";
import OverviewError from "./components/OverviewError";

export default {
  name: "b2b-manage-overview",

  mixins: [translateMixin],

  components: {
    OverviewTable,
    OverviewTitle,
    OverviewCategories,
    OverviewOnboarding,
    OverviewError,
  },

  data: () => ({
    categoriesKey: 0,
    tableKey: 0,
    agreements: [],
    agreementsLoading: true,
    categoriesState: undefined,
    organizations: [],
    organizationsState: STATE.LOADING,
    scopeId: null,
    selectedAgreement: null,
    selectedOrganization: null,
    selectedMainCategory: null,
    selectedMainCategoryHasItems: "UNKNOWN",
    selectedSubCategories: {},
    tableState: undefined,
    modalOpen: false,

    initialLoad: true,
  }),

  mounted() {
    // NOT suitable for persisted-data-service (data is dropped from that after 3 months)
    // consider moving to to b2b-user-settings
    if (!window.localStorage.getItem("manageOnboardingWatched")) this.openOnboardingModal();

    window.addEventListener("popstate", this.onPopState);
  },

  destroyed() {
    window.removeEventListener("popstate", this.onPopState);
  },

  async created() {
    await translateSetup(["mybusiness"]);
    document.title = this.t("MANAGE_OVERVIEW.DOCUMENT_TITLE");

    this.scopeId = await getScopeId();

    prefetchPersistedStates(this.scopeId);

    // set default values before knowing all available options (getOrganizations etc),
    // so that other components can load in parallell
    this.selectedOrganization = getTemporaryState("SELECTED_ORGANIZATION", "ALL");
    this.selectedAgreement = getTemporaryState("SELECTED_AGREEMENT", "ALL");
    this.selectedSubCategories = getTemporaryState("SELECTED_SUBCATEGORIES", {});

    this.updateSelectedMainCategoryFromUrl();

    await Promise.allSettled([this.getOrganizations(), this.getAgreements(true)]);
  },

  methods: {
    async getAgreements(initialLoad) {
      // called on first page load and when selectedOrganization changes
      this.agreementsLoading = true;

      // Try load from history object,
      // to improve performance and remove risk of de-sync with selectedAgreement)
      let alreadyLoadedAgreements = null;
      if (initialLoad) {
        alreadyLoadedAgreements = getTemporaryState("AGREEMENTS", null);
      }

      if (alreadyLoadedAgreements) {
        this.agreements = alreadyLoadedAgreements;
      } else {
        this.agreements = [];
        // has internal catching/logging, should never fail
        this.agreements = await getAgreements(this.scopeId, this.selectedOrganization, this.t);
        setTemporaryState("AGREEMENTS", this.agreements);
      }
      this.agreementsLoading = false;
    },
    async getOrganizations() {
      // only called on first page load
      this.organizationsState = STATE.LOADING;

      // Try load from history object,
      // to improve performance and remove risk of de-sync with selectedOrganization)
      const alreadyLoadedOrganizations = getTemporaryState("ORGANIZATIONS", null);
      if (alreadyLoadedOrganizations) {
        this.organizations = alreadyLoadedOrganizations;
        this.organizationsState = STATE.SUCCESS;
      } else {
        try {
          this.organizations = await getOrganizations(this.t);
          setTemporaryState("ORGANIZATIONS", this.organizations);
          this.organizationsState = STATE.SUCCESS;
        } catch (error) {
          logError("b2b-manage-overview", "Failed to load organizations");
          setTemporaryState("ORGANIZATIONS", undefined);
          this.organizationsState = STATE.ERROR;
        }
      }
    },
    onCategoriesState(categoriesState) {
      // Called whenever categories state (LOADING/ERROR/SUCCESS) changes, according to OverviewCategories.
      // changing categories does NOT call this
      this.categoriesState = categoriesState;
    },
    async onReloadClick() {
      if (this.organizationsState === STATE.ERROR) {
        window.location.reload();
        return;
      } else if (this.categoriesState === STATE.ERROR) {
        this.categoriesKey++;
      }

      this.tableKey++;

      this.categoriesState = undefined;
      this.tableState = undefined;
    },
    onSelectedAgreement(selectedAgreement) {
      this.selectedAgreement = selectedAgreement;
      setTemporaryState("SELECTED_AGREEMENT", selectedAgreement);
    },
    onSelectedOrganization(selectedOrganization) {
      // triggered by OverviewTitle sub component only

      // idea for refactoring: call this from created to remove some duplication
      this.selectedOrganization = selectedOrganization;
      setTemporaryState("SELECTED_ORGANIZATION", selectedOrganization);
      this.onSelectedAgreement("ALL");
      this.getAgreements(false);
    },
    onSelectedMainCategory(selectedCategory) {
      this.navigateToSelectedCategory(selectedCategory);
    },
    onSelectedMainCategoryHasItemsChanged(newValue) {
      // newValue = 'HAS_ITEMS', 'HAS_NO_ITEMS', 'UNKNOWN'
      this.selectedMainCategoryHasItems = newValue;
    },
    onSelectedSubCategories({ mainCategory, subCategories }) {
      this.selectedSubCategories = {
        ...this.selectedSubCategories,
        [mainCategory]: subCategories,
      };
      setTemporaryState("SELECTED_SUBCATEGORIES", this.selectedSubCategories);
    },
    onPopState() {
      this.updateSelectedMainCategoryFromUrl();
    },
    openOnboardingModal() {
      this.modalOpen = true;
    },

    getCurrentCategoryUrl() {
      return (
        match(`/foretag/mybusiness/${this.scopeId}/hantera/produkter-tjanster/:categoryurl?`)(
          window.location.pathname
        ).params?.categoryurl || ""
      );
    },
    updateSelectedMainCategoryFromUrl() {
      // called by created (first load) OR onPopState (= when URL changes, either from sspa navigation, or by navigateToSelectedCategory)

      const categoryUrl = this.getCurrentCategoryUrl();

      const newCategory = getMainCategories(true).find(
        (categoryCandidate) => categoryCandidate.url === categoryUrl
      )?.name;

      if (newCategory !== this.selectedMainCategory) {
        this.selectedMainCategory = newCategory;
      }
    },
    navigateToSelectedCategory(newMainCategory) {
      // 1. OverviewCategories emits onSelectedMainCategory
      // 2. navigateToSelectedCategory
      //      updates browser URL
      // 3. onPopState calls updateSelectedMainCategoryFromUrl
      //      also sets this.selectedMainCategory

      const selectedMainCategoryPath = getMainCategories(true).find(
        (categoryCandidate) => categoryCandidate.name === newMainCategory
      ).url;

      if (this.getCurrentCategoryUrl() !== selectedMainCategoryPath) {
        // change page URL
        // Must preserve "history.state", we keep selected subcategories in that.
        // Using replaceState means it replaces the current broswer history item.
        // Using pushState creates new history item, so user can go back to previous state.
        //   that would probably work as well, if we prefer that behaviour.
        if (selectedMainCategoryPath === "") {
          history.replaceState(
            history.state,
            "",
            `/foretag/mybusiness/${this.scopeId}/hantera/produkter-tjanster`
          );
        } else if (!window.location.pathname.includes(selectedMainCategoryPath)) {
          history.replaceState(
            history.state,
            "",
            `/foretag/mybusiness/${this.scopeId}/hantera/produkter-tjanster/${selectedMainCategoryPath}`
          );
        }
      }
    },

    onTableState(tableState) {
      this.tableState = tableState;
    },
  },

  computed: {
    hasError() {
      return this.states.some((state) => state === STATE.ERROR);
    },
    states() {
      return [this.organizationsState, this.categoriesState, this.tableState];
    },
    selectionForOverviewTable() {
      return {
        scopeId: this.scopeId,
        agreement: this.selectedAgreement,
        organization: this.selectedOrganization,
        mainCategory: this.selectedMainCategory,
        subCategories: this.selectedSubCategories[this.selectedMainCategory],
      };
    },
  },

  watch: {
    selectedOrganization: {
      handler(newValue, oldValue) {
        if (oldValue !== null) {
          analytics.trackEvent(analytics.category.MANAGE, analytics.action.FILTER, "Organisation");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/variables";

.manage-overview {
  padding-bottom: $telia-spacing-48;

  &__header {
    @include telia-bg-color("gray-50");
    padding-top: $telia-spacing-24;
    padding-bottom: $telia-spacing-48;
  }
}
</style>
